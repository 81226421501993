import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_POST_CONTENT } from '../scripts/queries'; // Import your GraphQL query
import { Container, Typography, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

const PostDetail = () => {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(GET_POST_CONTENT, {
    variables: { slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  const { title, content, author } = data.posts[0];

  return (
    <Container sx={{ maxWidth: '800px', margin: 'auto', py: 4 }}>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" align="right">
        - {author.name}
      </Typography>
      <Typography
            sx={{
            mt: 3,
            '& p': {
                fontSize: '1rem',
                lineHeight: '1.6',
                marginBottom: '1.2em',
                textAlign: 'justify', // Fill lines by justifying text
            },
            '& img': {
                maxWidth: '100%', // Ensure images don't exceed the container width
                height: 'auto',
                borderRadius: '8px',
                marginBottom: '1.2em',
            },
            // Customize other HTML elements as needed
            }}
            dangerouslySetInnerHTML={{ __html: content.html }}
        />      
        <Button sx={{ m: 4, p: 2 }} variant="contained" component={NavLink} to='/BlogPage'>
                Back to the Blog
            </Button>
    </Container>
  );
};

export default PostDetail;
