import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme';
import { CssBaseline } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';


if (process.env.NODE_ENV !== 'production') {
  require('dotenv').config();
}

const client = new ApolloClient({
  uri: 'https://ap-southeast-2.cdn.hygraph.com/content/cljavlk9m2g6101uedt62ady7/master',
  cache: new InMemoryCache(),
});

const container = document.getElementById('ikigai-react-app');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
