import { Container, Typography, Button } from '@mui/material';
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import Header from './Header'


export class About extends Component {
  render() {
    return (
        <Container sx={{ display: 'flex', 
                         flexDirection: 'column', 
                         justifyContent: 'center', 
                         textAlign: 'justify', 
                         alignItems: 'center', 
                         marginLeft: 'auto',
                         marginRight: 'auto',
                         paddingX: '0', // Adjust the padding value as needed
                         '@media (min-width: 600px)': {
                         paddingX: '12px', // Adjust the padding value for larger screens if necessary
                    }}}>
            <Header />

            <Typography variant="h5" sx={{textAlign: "center"}}>Discover your ideal profession with the power of ikigai</Typography>
            <Typography variant="body1">
                What is Ikigai? Ikigai is a Japanese concept that represents the perfect harmony between what you love, what you are good at, what the world needs, and what you can be rewarded for. It's the sweet spot where your passion, mission, vocation, and profession intersect.
            </Typography>
            <div className="image-container">
                <img src="https://s3.ap-southeast-2.amazonaws.com/www.ikigaidreamjob.com/IkigaiRK.jpg" alt="Ikigai 1" style={{ maxWidth: '100%', height: 'auto' , marginTop: 20, marginBottom: 20}} />
            </div>
            <Typography variant="body1">
                Unlock Your Potential with Our Ikigai-Based Tool: Finding your ideal profession can be a daunting task, but our web tool is here to simplify the process. Our unique approach combines the principles of Ikigai with cutting-edge technology to help you uncover your true calling and achieve fulfilment in your career.
            </Typography>
            <Typography variant="body1">
                How Does It Work? Our tool guides you through a series of thought-provoking questions, designed to delve deep into your passions, talents, values, and aspirations. By exploring these key areas, we help you gain a comprehensive understanding of yourself and discover the career paths that align perfectly with your Ikigai.
            </Typography>
            <Button sx={{ m: 4, p: 2 }} variant="contained" component={NavLink} to='/Form'>
                Find your Ikigai
            </Button>
      </Container>
        )
  }
}

export default About