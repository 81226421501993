import {cleanUserInput} from './cleanUserInput'
import invertedJSON from './dataSamples/invertedJSONdata3.json'
import { dictionaryApi } from './dictionaryApi'
// import dbRequest from './dbrequest'


// console.log("credentials key AWS: ", Object.keys(process.env))
// console.log("test test ", dbRequest("art"))

export const createScoredList = async(inputObj) => {
    
    const objectWithScoredPros = {
        love: {}, 
        skill: {}, 
        profession: {}, 
        mission: {}
      };// this will store resulted professions for each keyword from user input. eg. objectWithScores["love"]["actor"] = 3
            
      // cleaning each of 4 inputs and requesting one by one. Result: for each word in the request - list of professions and score
            for (const [iComponent,dirtyString] of Object.entries(inputObj)) {  // 1. take each of 4 inputs

                const cleanInputList = cleanUserInput(dirtyString); // create a temporary clean array out of input with keywords

                // loop through cleaned array and add synonyms
                for (const keyword of cleanInputList){

                    // loop through an array and add counter to each profession found in dict (invertedJSON)
                    function addToScore(listOfKeywords) {//add try catch!!!!!!!!!!!!!!!!!!!!! ------!!!!!!
                        for (const keyword of listOfKeywords){
                            // get professions by the keyword. this will return a list of associated professions
                            // console.log('checking if ', keyword, 'is in Dictionary. found in these professions: ', invertedJSON[keyword])
                            if (invertedJSON[keyword]){  // !!!!check if the keyword in the dictionary. Good to store these words for future to add them!!!!!!
                                const listOfProsByCurrentKey = invertedJSON[keyword];
                                // console.log(listOfProsByCurrentKey, typeof listOfProsByCurrentKey)
                                listOfProsByCurrentKey.forEach( pro => {
                                        if(objectWithScoredPros[iComponent][pro]){
                                            objectWithScoredPros[iComponent][pro]++;
                                        } else {
                                            objectWithScoredPros[iComponent][pro] = 1;
                                        }
        
                                    })
                                }
                        };
                    }

                    async function addToScoreAndSynonyms(keyword) {
                        // First, add Clean User Input into ScoredPros list
                        addToScore(cleanInputList);
                            ///// the code below is getting processed after the main script is finished. I need to wait for it to finish !!!
                        const returnedPromise = await dictionaryApi(keyword)
                        
                            try {addToScore(returnedPromise) /// adding only first meaning and ALL synonyms. Can change to 1-3 first meanings and only ~10 synonyms if available)
                            }catch (error) {console.log(error)}
                            return objectWithScoredPros;

                    }

                    const tempHolder = await addToScoreAndSynonyms(keyword);
                    if (!tempHolder){continue}
                
                }

                
            }
            
            // console.log('555 finished createScoredList on ', inputObj, ' The list is ', objectWithScoredPros);
            return objectWithScoredPros;

}