import React, { useReducer, useState } from 'react'
import {createScoredList} from './scripts/createScoredList'
import {checkOverlapAndResult} from './scripts/checkOverlapAndResult'
import { Container, FormControl, Button, Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Spinner from './Spinner';
import Header from './Header';
import { useIpInfo } from './geolocationAPI';
import storeFormSubmission from './scripts/dbStoreInfo';
import { Link } from 'react-router-dom';


const Form = (proObj) => {

    const formReducer = (state, event) => {
        if(event.reset) {
            return { // MAKE SURE THIS IS EMPTIED AFTER TESTING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                love: '', //'advertising, marketing, strategy, idea, order, law, social science, history',
                skill: '', //'problem solving, idea, time-management, people person',
                profession: '', //'sales, project management, organising events, trade relations',
                mission: '' //'equal rights, reduction of poverty, fair trade, fair go, sustainability'
            }
        }
        return {
            ...state,
            [event.name]: event.value
        }
    }

    const [formData, setFormData] = useReducer(formReducer, {});
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [ikigaiState, setIkigaiState] = useState({});
    const ipInfo = useIpInfo(); // Access IP information using the custom hook


    //Main logic for submit button
    const handleSubmit = async(event) => {
        event.preventDefault(); //prevents page reload
        setSubmitting(true); //helps trigger inputs lock while processing    
        // Reset ikigaiState to an empty object
        setIkigaiState({});
        const scoredListObj = await createScoredList(formData,proObj);

        const yourIkigai = checkOverlapAndResult(scoredListObj);
        if(Object.keys(yourIkigai).length){ // set Final scored list into the output, otherwise advise to add more info
            // Extract keys (professions) from yourIkigai object
            const professions = Object.keys(yourIkigai);
            const top15Professions = professions.slice(0, 15);  // Take only the top 15 professions
            const top15Ikigai = {};                             // Create an object with only the top 15 professions and their scores
            top15Professions.forEach(profession => {
                top15Ikigai[profession] = yourIkigai[profession];
            });

            setIkigaiState(prevState => ({
                ...prevState,
                ...top15Ikigai,
              }));
              // Additional step: Send data to DynamoDB along with IP information if yourIkigai is not empty
              if (top15Ikigai) {
                  try {
                    //   console.log("Submitting form data to DynamoDB:", formData, ipInfo, top15Ikigai);
                      await storeFormSubmission(formData, ipInfo, top15Ikigai);
                    //   console.log('DynamoDB: Successfully stored form submission');
                      // Handle success, show message, etc.
                  } catch (error) {
                      console.error('Error storing data in DynamoDB:', error);
                      // Handle error, show error message, etc.
                  }
              }
        }else{setIkigaiState({"Error":"Hm... that didn't work. Please try to add more information above. Make sure each IKIGAI component has at least 5-6 keywords"})}
        // console.log('finished processing. Your top professions are: ', yourIkigai)
        setSubmitting(false); //helps trigger inputs lock while processing

    }

    const handleReset = event => {
        event.preventDefault();
        setFormData({
            reset: true
        })
    }
    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    return (
        <Container sx={{ display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            textAlign: 'justify', 
            alignItems: 'center', 
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingX: '0', // Adjust the padding value as needed
            '@media (min-width: 600px)': {
            // paddingX: '12px', // Adjust the padding value for larger screens if necessary
         }}}>
        <Header />
        <form onSubmit={handleSubmit} onReset={handleReset} style={{borderRadius:'25px'}} sx={{ borderRadius: '16px' }}>     
            <FormControl variant="filled" sx={{maxWidth:"sm"}}>
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <fieldset disabled={submitting}>
                       <Tooltip title="Tip: often, early passions hold clues to what truly brings you joy. 
                                    Ask your parents and childhood friends (Please write at least 5 words)" 
                                    enterTouchDelay={50} leaveTouchDelay={5000} arrow>
                            <TextField 
                                name="love" // don't change
                                label="1. What you love" 
                                InputLabelProps={{ shrink: true }}
                                placeholder="e.g. Travel, cooking, watching movies, sport"
                                variant="outlined" 
                                value={formData.love || ''} 
                                onChange={handleChange} 
                                required 
                                fullWidth 
                                multiline
                                inputProps={{ minLength: 15 }}

                            />
                        </Tooltip>
                        <Tooltip title="Tip: reflect on your skills, talents, and strengths (Please write at least 5 words)" enterTouchDelay={50} leaveTouchDelay={5000} arrow>
                            <TextField                             
                                name="skill"  // don't change
                                label="2. What you are good at" 
                                InputLabelProps={{ shrink: true }}
                                placeholder="e.g. Problem-solving, communication, technology"
                                variant="outlined" 
                                value={formData.skill || ''} 
                                onChange={handleChange} 
                                required 
                                fullWidth 
                                multiline
                            />
                        </Tooltip>
                        <Tooltip title="Tip: consider what services, products, or solutions people are willing to pay for (Please write at least 5 words)" 
                                enterTouchDelay={50} leaveTouchDelay={5000} arrow>
                            <TextField 
                                name="profession"  // don't change
                                label="3. What you can be paid for" 
                                InputLabelProps={{ shrink: true }}
                                placeholder="e.g. Sales, teaching, event planning or management"
                                variant="outlined" 
                                value={formData.profession || ''} 
                                onChange={handleChange} 
                                required 
                                fullWidth 
                                multiline
                            />
                        </Tooltip>
                        <Tooltip title="Tip: reflect on societal or global challenges that resonate with you (Please write at least 5 words)" enterTouchDelay={50} leaveTouchDelay={5000} arrow>
                            <TextField 
                                name="mission"  // don't change
                                label="4. What the World needs" 
                                InputLabelProps={{ shrink: true }}
                                placeholder="e.g. Renewable energy, social justice, animal care"
                                variant="outlined" 
                                value={formData.mission || ''} 
                                onChange={handleChange} 
                                required 
                                fullWidth 
                                multiline
                            />
                        </Tooltip>
                    </fieldset>
                </Grid>

                <Grid container justifyContent="center" alignItems="center">
                    {/* Checkbox for accepting Terms and Conditions */}
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                            name="acceptTerms"
                            color="primary"
                            />
                        }
                        label={
                            <Typography variant="body1" color="textSecondary">
                            I have read and accept the
                            </Typography>
                        }
                        />
                        <Link to="/terms">Terms and Conditions</Link>
                </Grid>
                
                <Grid item style={{ width: '100%' }}>
                    <Grid alignSelf="center" style={{ textAlign: 'center', padding: '20px' }}>
                        <Button variant="contained" type='submit' color='success' disabled={!acceptTerms || submitting}>
                            Find ikigai
                        </Button>
                        <Button variant="contained" type='reset' color='primary' disabled={submitting} style={{ marginLeft: '10px' }}>
                            Reset 
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            </FormControl>

            <Spinner submitting={submitting} /> 

            {Object.keys(ikigaiState).length > 1 && ( // If ikigaiState has some results -> display list of professions with their score limited to .slice(0,40)
            <>
                <Typography variant="h6" component="h6" sx={{ padding: 2 }}>
                Your IKIGAI professions are:
                </Typography>
                <ol type="1" style={{maxWidth: '600px'}}>
                    {Object.keys(ikigaiState)
                        .slice(0, 15)
                        .map((key, i) => (
                        <li key={i} style={{ listStyleType: 'decimal' }}>
                            <Typography variant="body1" component="span">{key}</Typography>&nbsp; - &nbsp;&nbsp;
                            <Typography variant="body1" component="span">{ikigaiState[key]}</Typography>
                        </li>
                    ))}
                </ol>
            </>
            )}

            {Object.keys(ikigaiState).length === 1 && ( //if ikigaiState has only one element it is most likely the error message
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{ padding: 2 }}
                    style={{
                        maxWidth: '600px',
                        margin: '20px auto', // Add more space above and below
                        padding: '0 16px', // Adjust padding as needed
                    }}
                    >
                    Error: Hm... that didn't work. Please try to add more information above. Make sure each IKIGAI component has at least 5-6 keywords
                </Typography>

            )}

        </form> 
        </Container>
    )
}

export default Form