export const cleanUserInput = (inputString) => {
        // console.log(inputString,typeof (inputString) )
        if (typeof inputString === 'string' && inputString){
            return inputString.split(/[^A-Za-z]/)
            .map(item => item.trim())
            .filter(Boolean);
       }
        else {
            console.error('Error. cleanUserInput recieved:', inputString, ' and the type is: ', typeof inputString);
        }

    
}