// TermsAndConditions.js

import React from 'react';
import Typography from '@mui/material/Typography';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <Typography variant="body1" component="p">Terms and Conditions</Typography>

      <Typography variant="body1" component="p"> 1. Acceptance of Terms</Typography>
        By accessing or using our website or services, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, you may not access or use our website or services.

      <Typography variant="body1" component="p">  2. Data Collection</Typography>
        We collect certain information from users, including personal information such as IP addresses, in order to provide and improve our services. This may include information provided voluntarily by users or automatically collected through cookies and other tracking technologies.

        <Typography variant="body1" component="p"> 3. Data Usage</Typography>
        We may use the data collected from users to customize their experience, provide targeted content and advertisements, and improve our services. We may also use the data for research and analytical purposes to better understand user preferences and behavior.

        <Typography variant="body1" component="p">4. Data Sharing</Typography>
        We may share users' data with third parties, including service providers, business partners, and advertisers, for purposes such as data processing, analytics, and marketing. We will not sell or rent users' personal information to third parties without their consent.

        <Typography variant="body1" component="p">5. Data Security</Typography>
        We take reasonable measures to protect users' data from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

        <Typography variant="body1" component="p">6. User Rights</Typography>
        Users have the right to access, correct, or delete their personal data held by us. Users may also request information about the types of data collected and the purposes for which it is used. Requests should be submitted to ikigaidreamjob@gmail.com.

        <Typography variant="body1" component="p">7. Cookies and Tracking Technologies</Typography>
        We use cookies and other tracking technologies to collect and store information about users' preferences and browsing activities. Users can manage their cookie preferences through their browser settings.

        <Typography variant="body1" component="p">8. Changes to Terms and Conditions</Typography>
        We reserve the right to update or modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. Users are encouraged to review these terms periodically for changes.

        <Typography variant="body1" component="p">9. Contact Information</Typography>
        If you have any questions or concerns about these terms and conditions or our data practices, please contact us at ikigaidreamjob@gmail.com.
    </div>
  );
};

export default TermsAndConditions;
