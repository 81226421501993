import axios from "axios";

export async function dictionaryApi(word) {
  const apiKey = "86EPl8wVKaxUEFsYDr9YRA==trUoVPyJwY79JAjk";

  const url = `https://api.api-ninjas.com/v1/thesaurus?word=${word.toLowerCase()}`;
  const headers = {
    Accept: "application/json",
    "X-Api-Key": apiKey,
  };

  try {
    const response = await axios.get(url, { headers });

    // console.log("Response data:", response.data); //debugging

    const result = response.data.synonyms;
    const texts = result ? result.map(item => item) : [];

    // console.log("Synonyms:", texts); //debugging

    return texts;
  } catch (error) {
    console.error("Error occurred:", error);
    return [];
  }
}


// Retired code v1. request dictionary info for a word
// export async function dictionaryApi (word) {
//     const params = {
//       Accept: "application/json",
//       'X-Api-Key': "86EPl8wVKaxUEFsYDr9YRA==trUoVPyJwY79JAjk"
//     };
//     // console.log("Trying https://api.dictionaryapi.dev/api/v2/entries/en/" + word);
//     const url = "https://api.api-ninjas.com/v1/thesaurus?word=" + word.toLowerCase()
//     const r = await axios.get(url, { headers: params }).catch(function (error) {
//       if (error.response) {
//         // Request made and server responded
//         console.log(error.response.data);
//         console.log(error.response.status);
//         console.log(error.response.headers);
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.log(error.request);
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.log('Error', error.message);
//       };
//     });

//     const result = r.data.synonyms
//     const texts = result ? result.map(item => item.text) : [];
//     console.log(texts)
//     if(texts.length > 0){
//         return texts;
//     }else{console.log("Dictionary API call error for word ", word, "r = ", r)}

// }

//Retired backup number2. request dictionary info for a word
// export async function dictionaryApi (word) {
    
//   // console.log("Trying https://api.dictionaryapi.dev/api/v2/entries/en/" + word);
//   const response = await axios.get("https://api.dictionaryapi.dev/api/v2/entries/en/" + word).catch(function (error) {
//       if (error.response) {
//         // Request made and server responded
//         console.log(error.response.data);
//         console.log(error.response.status);
//         console.log(error.response.headers);
//       } else if (error.request) {
//         // The request was made but no response was received
//         console.log(error.request);
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.log('Error', error.message);
//       };
//   });

//   if(response){
//       return response.data[0]['meanings'];
//   }else{console.log("Dictionary API call error")}

// }