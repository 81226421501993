import { Container, Typography } from '@mui/material';
import React from 'react';

const Support = () => {
  return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingX: '0', // Adjust the padding value as needed
          '@media (min-width: 600px)': {
            paddingX: '12px', // Adjust the padding value for larger screens if necessary
          },
        }}
      >
        <Typography variant="h5" gutterBottom>
          Support page is under development
        </Typography>
        <Typography variant="body1" paragraph>
          For any feedback or assistance, please reach out to us at:
        </Typography>
        <Typography variant="body1">
          <a href="mailto:ikigaidreamjob@gmail.com">ikigaidreamjob@gmail.com</a>
        </Typography>
      </Container>
  );
};

export default Support;
