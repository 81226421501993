import React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

const Header = ({text}) => {

    return (
        <Container style={{textAlign: 'center', marginTop: "7%", marginBottom: "7%"}} >
            <Typography variant="h4" component="h2"  style={{ fontFamily: 'sohne' }}>
                IKIGAI
            </Typography>            
            <Typography variant="h6" component="h1" gutterBottom style={{ fontFamily: 'sohne' }}>The reason for being</Typography>
        </Container>
    )
}

Header.defaultProps = {
    text: 'IKIGAI',
}


export default Header
