import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#333333', // Dark Gray
    },
    secondary: {
      main: '#C4E1FF', // Light Blue
    },
    background: {
      default: '#F5F5F5', // Light Gray
      paper: '#FFFFFF', // White
    },
  },
  typography: {
    h5: {
      marginBottom: '1em', // Add margin at the bottom
    },
    body1: {
      margin: '1rem 0', // Add margin both before and after
    },
  },

  fontFamily: [
    'Poppins',     
    'sans-serif',
    'sohne',
    '"Helvetica Neue"',
    'Helvetica',
    'Arial',

    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

theme = responsiveFontSizes(theme);

export default theme