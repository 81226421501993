// curl "https://ipinfo.io?token=8ad795b6ccd8ec"
// IpInfoProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const IpInfoContext = createContext();

export const useIpInfo = () => {
  const context = useContext(IpInfoContext);
  if (!context) {
    throw new Error('useIpInfo must be used within an IpInfoProvider');
  }
  return context.ipInfo;
};

export const IpInfoProvider = ({ children }) => {
  const [ipInfo, setIpInfo] = useState(null);

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=8ad795b6ccd8ec');
        setIpInfo(response.data);
      } catch (error) {
        console.error('Error fetching IP information:', error);
      }
    };

    fetchIpInfo();
  }, []);

  const contextValue = {
    ipInfo,
  };

  return <IpInfoContext.Provider value={contextValue}>{children}</IpInfoContext.Provider>;
};

export default IpInfoProvider;
