import { gql } from '@apollo/client';

export const GET_POSTS = gql`
query GetPosts{
  posts {
    createdAt
    datePublished
    id
    publishedAt
    slug
    title
    updatedAt
    content {
      html
    }
    author{
        id
        name
        createdBy{
            picture
            name
        }
    }
    coverPhoto{
            url     
        }
  }
}
`;

export const GET_POST_CONTENT = gql`
  query GetPostContent($slug: String!){
    posts(where: {slug: $slug}) {
      id
      title
      slug
      datePublished
      author{
        id
        name
      }
      content{
        html
      }
      coverPhoto{
        id
        url
      }
    }
  }
`;

export const GET_SLUGLIST = gql`
  query GetSluglist{
    posts {
      slug
    }
  }
`;
