import React from 'react';
import Typography from '@mui/material/Typography';
import { Container} from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Container style={{ textAlign: 'center', marginTop: '3%', marginBottom: '3%' }}>
      <Typography variant="body1" component="p">
        © {new Date().getFullYear()} ikigaidreamjob.com . All rights reserved. <Link to="/terms">Terms and Conditions</Link>
      </Typography>
    </Container>
  );
};

export default Footer;
