import React from 'react';
import { Container, Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_POSTS } from './scripts/queries.js'; // Import your GraphQL query
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(3),
    width: 300,
    height: 430,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    width: '100%',
    textAlign: 'center',
  },
  media: {
    height: 200,
  },
}));

function BlogPage() {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_POSTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom sx={{marginY :'7%'}}>
        Welcome to the Blog
      </Typography>
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        {data.posts.map(({ id, title, slug, coverPhoto, author, content }) => (
          <Link key={id} to={`/posts/${slug}`} style={{ textDecoration: 'none' }}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <CardMedia
                  component="img"
                  height="194"
                  image={coverPhoto.url}
                  alt={slug}
                />
                <Typography variant="h5" component="h2" gutterBottom>
                  {title}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" align="right">
                  - {author.name}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        ))}
      </Grid>

    </Container>
    
  );
}

export default BlogPage;
