import React from 'react';
import { Grid } from '@mui/material';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
  } from "react-share";

const Social = () => {

return (
    <Grid container direction="row" justifyContent="center" alignItems="center" columnSpacing={1} margin={'3rem 0'}>
        <Grid item>
            <FacebookShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <FacebookIcon size={50} round />
            </FacebookShareButton>
        </Grid>

        <Grid item>
            <LinkedinShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <LinkedinIcon size={50} round />
            </LinkedinShareButton>
        </Grid>

        <Grid item>
            <TwitterShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <TwitterIcon size={50} round />
            </TwitterShareButton>
        </Grid>

        <Grid item>
            <PinterestShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <PinterestIcon size={50} round />
            </PinterestShareButton>
        </Grid>

        <Grid item>
            <RedditShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <RedditIcon size={50} round />
            </RedditShareButton>
        </Grid>

        <Grid item>
            <WhatsappShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <WhatsappIcon size={50} round />
            </WhatsappShareButton>
        </Grid>

        <Grid item>
            <TelegramShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <TelegramIcon size={50} round />
            </TelegramShareButton>
        </Grid>

        <Grid item>
            <EmailShareButton
                url={'https://www.ikigaifinder.com'}
                quote={'Find your IKIGAI!'}
                hashtag="#IKIGAI, #IKIGAIfinder, #Purpose, #PerfectJob, #DreamJob, #BestProfession"
            >
                <EmailIcon size={50} round />
            </EmailShareButton>
        </Grid>
    </Grid>
      
)
}


export default Social
