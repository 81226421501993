/**
 * Copyright 2010-2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * This file is licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License. A copy of
 * the License is located at
 *
 * http://aws.amazon.com/apache2.0/
 *
 * This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
*/

  
const AWS = require('aws-sdk');

AWS.config.update({
    region: "ap-southeast-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    endpoint: "https://dynamodb.ap-southeast-2.amazonaws.com"
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();
const tableName = 'ikigaiLog';

export default async function storeFormSubmission(formData, ipInfo, yourIkigai) {
  const timestamp = new Date().toISOString();

  const params = {
    TableName: tableName,
    Item: {
      timestamp: timestamp,
      ipInfo: ipInfo,
      formData: formData,
      yourIkigai: yourIkigai,
    },
  };

  try {
    await dynamoDB.put(params).promise();
    // console.log('Form submission stored successfully.');
  } catch (error) {
    // console.error('Error storing form submission:', error);
  }
};


// module.exports = storeFormSubmission;