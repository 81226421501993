import React, { Component } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Container from '@mui/material/Container';
import DrawerAppBar from './components/DrawerAppBar';
import Social from './components/Social';
import PostDetail from './components/posts/[slug]'; // Import the dynamic route component
import About from './components/About';
import Form from './components/Form';
import BlogPage from './components/BlogPage';
import Support from './components/Support';
import TermsAndConditions from './components/TermsAndConditions';
import Footer from './components/Footer';
import { IpInfoProvider } from './components/geolocationAPI';


class App extends Component {

  render() {
    return (  
      <Router>
        <IpInfoProvider>
          <Container sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <DrawerAppBar />
            <Routes>
              <Route path="/posts/:slug" element={<PostDetail />}/>
              <Route path="/About" element = {<About />}/>
              <Route path="/" element = {<About />}/>
              <Route path="/Form" element = {<Form />}/>,
              <Route path="/BlogPage" element = {<BlogPage />}/>,
              <Route path="/Support" element = {<Support />}/>
              <Route path="/terms" element={<TermsAndConditions/>} />
              {/* Add more routes as needed */}
            </Routes>
            <Social />
            <Footer />
          </Container>
        </IpInfoProvider>
      </Router>
    );
  }
}

export default App;
