export const checkOverlapAndResult = (inputScoredObj) => {

    const totalSums = {}; /* this will store total summs from previous 'scoring' steps. later we take items found 
                            in all 4 IKIGAI properties and use the score from here to spit out the final Result*/
    
    // loop through "love" component scored list only and check if any item is in all other lists (OPTIMIZE LATER)
    for (const prof in inputScoredObj["love"]){
        if (inputScoredObj["skill"].hasOwnProperty(prof)&&
            inputScoredObj["profession"].hasOwnProperty(prof)&&
            inputScoredObj["mission"].hasOwnProperty(prof)
        ){
            totalSums[prof] = inputScoredObj["love"][prof] + inputScoredObj["skill"][prof] + inputScoredObj["profession"][prof] + inputScoredObj["mission"][prof]; // add scores
        }
    }
    // Convert to list in order to sort by value
    const sortedScoredList = Object.fromEntries(
        Object.entries(totalSums).sort(([,a],[,b]) => b-a)
    );
    console.log(sortedScoredList)
    return sortedScoredList
}

/* 
This module takes in objectWithScores["love"]["actor"] = 3
and checks if this profession is found across all 4 IKIGAI elements
before spitting out resulted list with most scored professions at the top and an empty list if no professions passed the criteria
*/