import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 999,
  },
  progress: {
    color: theme.palette.primary.main,
  },
}));

export default function Spinner(props) {
  const { submitting } = props;
  const classes = useStyles();

  return (
    submitting && (
      <div className={classes.root}>
        <div style={{ textAlign: 'center' }}>
          <CircularProgress className={classes.progress} />
          <Typography variant="h6" style={{ backgroundColor: 'white', padding: '10px' }}>
            Please wait. We are working hard to find your ikigai. This might take several minutes...
          </Typography>
        </div>
      </div>
    )
  );
}
