import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import About from './About';
import Form from './Form';
import BlogPage from './BlogPage';
import Support from './Support';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;
const navItems = [
  { label: 'ABOUT', path: '/About' , element: <About />},
  { label: 'IKIGAI FINDER', path: '/Form' , element: <Form />},
  { label: 'BLOG', path: '/BlogPage' , element: <BlogPage />},
  { label: 'SUPPORT THE PROJECT', path: '/Support' , element: <Support />},
];

function DrawerAppBar({ window }) {

  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton component={NavLink} to={item.path} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '10vh' }}>
      <AppBar elevation={0} component="nav" position="sticky" sx={{
        color: theme.palette.primary.main, // Use the primary color defined in your theme
        backgroundColor: theme.palette.background.default, // Use the background color defined in your theme
        marginBottom: theme.spacing(2), // Apply margin using theme spacing
        paddingLeft: theme.spacing(1), // Apply padding using theme spacing
        alignItems: { xs: 'start', sm: 'center' },
      }}>        
        <Toolbar sx={{ alignItems: 'left'}}>
            <IconButton
              color="#000"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item.label} component={NavLink} to={item.path} sx={{ color: '#000' }}>
                  {item.label}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
